<template>
  <div class="EmptyStateTitle">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.EmptyStateTitle {
  font-size: var(--size-24);
  font-weight: 500;
}
</style>
