<template>
  <div class="EmptyState">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.EmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
  border-radius: 10px;
  gap: 24px;
  background: var(--white);
}
</style>
